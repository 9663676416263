import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className='CTA-header'>
        ready to design something amazing?
        </p>
        
        <a href="https://wwcnj4r6579.typeform.com/to/UoROABX2" className='CTA-form-button' aria-label="Click here to use our contact form.">Let's get wicked.</a>

      </header>
      <div className='coming-soon-container'><span className='coming-soon'>coming soon. © {new Date().getFullYear()} wicked oni.</span></div>
      
    
  
    </div>
  );
}

export default App;
